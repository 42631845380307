<template>
  <div class="on-boarding">
    <!-- Claim Free Account -->
    <b-row class="main-row" id="get-start" v-if="page_no == 1">
      <b-col sm="12" md="7" class="bg-white">
        <div class="left-content text-center free-acc">
          <!-- Brand logo-->
          <b-link class="brand-logo pb-3 d-block" :to="{ name: 'home' }">
            <b-img
              style="width: 150px"
              :src="appDarkLogo"
              alt="logo"
            />
          </b-link>
          <!-- /Brand logo-->
          <b-card-title title-tag="h1" class="font-weight-bold text-align-center font-weight-bolder mt-5">Claim Your FREE Account</b-card-title>
          <b-card-text class="text-align-center">Get started for free</b-card-text>
           <!-- email -->
           <validation-observer ref="step1">

           <b-form-group label-for="account-e-mail" class="onboard-form" >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input autocomplete="off"
                      id="register-email"
                      v-model="formData.email"
                      name="register-email"
                      :state="errors.length > 0 ? false:null"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <b-button variant="primary" block class="form-continue" @click="validationStep1">Continue</b-button>
                <!-- divider -->
                <div class="divider mt-2 mb-.5">
                    <div class="divider-text">
                      Or
                    </div>
                  </div>
                <span class="text-center">Continue with</span>
                <div class="other-login d-flex align-items-center justify-content-center">
                  <b-link href="#" class="btn-login d-flex align-items-center justify-content-center"  @click="SocialAuthProvider('microsoft')">
                    <b-img fluid :src="require('@/assets/images/icons/login/microsoft.svg')" width="20px" height="20px" class="before-hover" />
                    <b-img fluid :src="require('@/assets/images/onboarding/microsoft.png')" width="31px" height="31px" class="after-hover" />
                    <span>Microsoft</span>
                  </b-link>
                  <!-- <b-link href="#" class="btn-login d-flex align-items-center justify-content-center"  @click="SocialAuthProvider('facebook')">
                    <b-img fluid :src="require('@/assets/images/icons/login/facebook.svg')" width="21px" height="21px" class="before-hover" />
                    <b-img fluid :src="require('@/assets/images/onboarding/facebook.png')" width="23px" height="23px" class="after-hover" />
                    <span>Facebook</span>
                  </b-link> -->
                  <b-link href="#" class="btn-login d-flex align-items-center justify-content-center"  @click="SocialAuthProvider('yahoo')">
                    <b-img fluid :src="require('@/assets/images/icons/login/yahoo.svg')" width="20px" height="20px" class="before-hover" />
                    <b-img fluid :src="require('@/assets/images/onboarding/yahoo-new.png')" width="20px" height="20px" class="after-hover" />
                    <span>Yahoo</span>
                  </b-link>
                  <b-link href="#" class="btn-login d-flex align-items-center justify-content-center"  @click="SocialAuthProvider('google')">
                    <b-img fluid :src="require('@/assets/images/icons/login/google.svg')" width="20px" height="20px" class="before-hover"/>
                    <b-img fluid :src="require('@/assets/images/onboarding/google.png')" width="22px" height="22px" class="after-hover" />
                    <span>Google</span>
                  </b-link>
                  <!-- <b-link href="#" class="btn-login d-flex align-items-center justify-content-center"  @click="SocialAuthProvider('apple')">
                    <b-img fluid :src="require('@/assets/images/icons/login/yahoo.svg')" width="20px" height="20px" class="before-hover"/>
                    <b-img fluid :src="require('@/assets/images/onboarding/yahoo-new.png')" width="22px" height="22px" class="after-hover" />
                    <span>Apple</span>
                  </b-link> -->
                  <!-- <b-link href="#" class="btn-login d-flex align-items-center justify-content-center"  @click="SocialAuthProvider('linkedin')">
                    <b-img fluid :src="require('@/assets/images/onboarding/before-hover-linkedIn.png')" width="21px" height="21px" class="before-hover" />
                    <b-img fluid :src="require('@/assets/images/onboarding/after-hover-linkedIn.png')" width="23px" height="23px" class="after-hover" />
                    <span>LinkedIn</span>
                  </b-link> -->
                </div>
            </b-form-group>
          </validation-observer>
            <p class="text-center mt-3">
                  <span>Already have an account?</span>
                  <b-link :to="{path:'/login'}">
                    <span>&nbsp;Log in</span>
                  </b-link>
                </p>
            </div>
      </b-col>
      <b-col sm="12" md="5" class="board-rightimg d-none d-md-flex align-items-center justify-content-center">
        <b-img fluid :src="require('@/assets/images/onboarding/Screen01.svg')" class="pricing-trial-img" alt="svg img"/>
      </b-col>
    </b-row>
    <!-- End Claim Free Account -->

    <!-- Setup your Account -->
    <b-row class="main-row" id="set-account" v-if="page_no == 2">
      <b-col sm="12" md="7" class="bg-white">
        <validation-observer ref="step2">
          <div class="left-content common-style">
            <!-- Brand logo-->
            <b-link class="brand-logo pb-3 d-block" :to="{ name: 'home' }">
            <b-img
              style="width: 150px"
              :src="appDarkLogo"
              alt="logo"
            />
          </b-link>
            <!-- /Brand logo-->
            <b-card-title title-tag="h1" class="font-weight-bold text-align-center font-weight-bolder mt-5">Set up your account</b-card-title>
            <!-- Name Form-->
            <b-form-group label="Full name" label-for="setup-fullname" >
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input autocomplete="off"
                  v-model="formData.username"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Your Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- password Form -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password"
                  >Password</label
                >
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input autocomplete="off"
                    id="login-password"
                    v-model="formData.password"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    placeholder="Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <validation-provider #default="{ errors }" name="Username" rules="required">
            <b-form-checkbox id="checkbox-1" v-model="formData.termsAgreed" name="checkbox-1" value="1" unchecked-value="0">
                <span>I agree to the <b-link @click="openTerms()">Terms Of Service</b-link> and <b-link @click="openPrivacy()">Privacy Policy</b-link></span>
            </b-form-checkbox>
          </validation-provider>
          </div>
          <div class="continue-btn back-text">
            <div class="d-flex align-items-center justify-content-between">
              <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="primary">
                <span class="align-middle" @click="page_no=1">Back</span>
              </b-button>
              <b-button variant="primary" block class="form-continue" @click="validationStep2">Continue</b-button>
            </div>
          </div>
        </validation-observer>
      </b-col>
      <b-col sm="12" md="5" class="board-rightimg d-none d-md-flex align-items-center justify-content-center">
        <b-img fluid :src="require('@/assets/images/onboarding/Screen02.svg')" class="pricing-trial-img" alt="svg img"/>
      </b-col>
    </b-row>
    <!-- End Setup your Account -->

    <!-- tellus company -->
    <b-row class="main-row" id="tellus" v-if="page_no == 3">
      <b-col sm="12" md="7" class="bg-white">

        <validation-observer ref="step3">
        <div class="left-content common-style">
          <!-- Brand logo-->
          <b-link class="brand-logo pb-3 d-block" :to="{ name: 'home' }">
            <b-img
              style="width: 150px"
              :src="appDarkLogo"
              alt="logo"
            />
          </b-link>
          <!-- /Brand logo-->
          <b-card-title title-tag="h1" class="font-weight-bold text-align-center font-weight-bolder mt-5">Tell us about you</b-card-title>
          <!--- Form -->
            <!-- Mode Form -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="application-mode"
                  >Application Mode</label
                >
              </div>
              <validation-provider
                #default="{ errors }"
                name="application-mode"
              >
              <div class="demo-inline-spacing">
                <b-form-radio
                  v-model="formData.application_mode"
                  name="company"
                  value="company"
                >
                  Company
                </b-form-radio>
                <b-form-radio
                  v-model="formData.application_mode"
                  name="community"
                  value="community"
                >
                  Community
                </b-form-radio>
              </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group v-if="formData.application_mode == 'company'" label-for="company-url" label="Job Title">
              <validation-provider
                    #default="{ errors }"
                    name="Job Title"
                    rules="required"
                  >
              <v-select
                        :clearable="false"
                        placeholder="Select Job Title..."
                        :searchable="true"
                        :options="jobTitleList"
                        v-model="formData.jobTitle"
                      />
              </validation-provider>
            </b-form-group>
            <b-form-group v-if="formData.application_mode == 'company'" label-for="company-url" label="Company URL">
              <validation-provider
                      #default="{ errors }"
                      name="Company Website URL"
                      rules="required"
                    >
              <b-form-input autocomplete="off" id="company-url" v-model="formData.company_website"  name="Companyurl" :state="errors.length > 0 ? false:null"  placeholder="https://example.com"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group :label="(formData.application_mode == 'company') ? 'Company Name' : 'Community Name'" label-for="company-name">
              <validation-provider #default="{ errors }" name="Company Name" rules="required">
                <b-form-input autocomplete="off" id="Company-Name" v-model="formData.company_name" name="Companyname" :state="errors.length > 0 ? false:null" @keyup="nameChange" placeholder="example"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
            <b-form-group>
              <label for="zirclydomain">Zircly Domain</label>
              <validation-provider
                    #default="{ errors }"
                    name="Domain"
                    rules="required"
                  >
              <b-input-group class="input-group-merge">
              <b-form-input autocomplete="off" id="zirclydomain" v-model="formData.company_domain" placeholder="example" 
              :state="errors.length > 0 ? false : null"/>
                <b-input-group-append is-text>.zircly.com</b-input-group-append>
              </b-input-group>
              </validation-provider>
            </b-form-group>
        </div>
        <div class="continue-btn back-text">
          <div class="d-flex align-items-center justify-content-between">
            <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" @click="page_no=2" variant="primary">
              <span class="align-middle">Back</span>
            </b-button>
            <b-button variant="primary" block class="form-continue tellus" @click="validationStep3">Continue</b-button>
          </div>
        </div>
        </validation-observer>
      </b-col>
      <b-col sm="12" md="5" class="board-rightimg d-none d-md-flex align-items-center justify-content-center" style="background-color: #A1D7FF">
        <b-img fluid :src="require('@/assets/images/onboarding/Screen03.svg')" class="pricing-trial-img" alt="svg img"/>
      </b-col>
    </b-row>
    <!-- end tellus company -->

    <!-- Choose module -->
    <b-row class="main-row" id="choose-module" v-if="page_no == 4">
      <b-col sm="12" md="7" class="bg-white">
        <div class="left-content common-style">
          <!-- Brand logo-->
          <b-link class="brand-logo pb-3 d-block" :to="{ name: 'home' }">
            <b-img
              style="width: 150px"
              :src="appDarkLogo"
              alt="logo"
            />
          </b-link>
          <!-- /Brand logo-->
          <b-card-title title-tag="h1" class="font-weight-bold text-align-center font-weight-bolder mt-5 mb-.75">Choose your modules</b-card-title>
          <b-card-text class="text-align-center mt-0">You can always add more in the future</b-card-text>
          <div class="choose-module">
            <b-form-group class="goal-type">
              <div class="d-flex align-items-center mt-2 list-radio">
                <b-form-checkbox v-model="formData.selectedModules" v-for="module in modulesList" :name="module" :value="module" class="mr-0 pl-0">{{module}}</b-form-checkbox>
                </div>
            </b-form-group>
          </div>
        </div>
        <div class="continue-btn back-text">
          <div class="d-flex align-items-center justify-content-between">
            <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" @click="page_no=3" variant="primary">
              <span class="align-middle">Back</span>
            </b-button>
            <b-button variant="primary" block class="form-continue choose-module-btn" @click="validationStep4">Continue</b-button>
          </div>
        </div>
      </b-col>
      <b-col sm="12" md="5" class="board-rightimg d-none d-md-flex align-items-center justify-content-center" style="background-color: #A1D7FF">
        <b-img fluid :src="require('@/assets/images/onboarding/Screen04.svg')" class="pricing-trial-img" alt="svg img"/>
      </b-col>
    </b-row>
    <!-- End Choose module -->
    
    <!-- upload employee data -->
    <!-- <b-row class="main-row" id="upload-data" v-if="page_no == 5">
      <b-col sm="12" md="7" class="bg-white">
        <div class="left-content upload-data common-style">
          <b-link class="brand-logo pb-3 d-block" :to="{ name: 'home' }">
            <b-img
              style="width: 150px"
              :src="appDarkLogo"
              alt="logo"
            />
          </b-link>
          <b-card-title title-tag="h1" class="font-weight-bold text-align-center font-weight-bolder mt-5">Upload employee data</b-card-title>
          <div class="upload-file">
            <b-button variant="primary" block class="form-continue">Download sample Data</b-button>
            <div class="divider mt-2 mb-.5">
              <div class="divider-text"> Or </div>
            </div>
            <div class="custom-file-uploads">
              <label class="btn-upload" for="myFile">Upload Data</label>
              <input type="file" name="myFile" accept=".csv">
              <ul class="custom-file-names p-0 text-center mt-1"></ul>
            </div>
          </div>
          
        <div class="continue-btn remind-text">
          <div class="d-flex align-items-center justify-content-between">
            <b-link href="" class="remind-me"> Remind me later</b-link>
              <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" @click="page_no = 4" variant="primary">
                <span class="align-middle">Back</span>
              </b-button>
            <b-button variant="primary" block class="form-continue upload-data-btn mt-0" @click="page_no = 6">Continue</b-button>
          </div>
        </div>
        </div>
      </b-col>
      <b-col sm="12" md="5" class="board-rightimg d-none d-md-flex align-items-center justify-content-center" style="background-color: #FFFF88">
        <b-img fluid :src="require('@/assets/images/onboarding/Screen05.svg')" class="pricing-trial-img" alt="svg img"/>
      </b-col>
    </b-row> -->
    <!-- End upload employee data -->
    
    <!-- Invite admin -->
    <b-row class="main-row" id="invite-team" v-if="page_no == 6">
      <b-col sm="12" md="7" class="bg-white">
        <validation-observer ref="step5">
        <div class="left-content common-style">
          <!-- Brand logo-->
          <b-link class="brand-logo pb-3 d-block" :to="{ name: 'home' }">
            <b-img
              style="width: 150px"
              :src="appDarkLogo"
              alt="logo"
            />
          </b-link>
          <!-- /Brand logo-->
          <b-card-title title-tag="h1" class="font-weight-bold text-align-center font-weight-bolder mt-5">Invite Your Admin Team</b-card-title>
          <b-form-group label-for="account-e-mail" v-for="person,k in formData.toBeInvitedList" :key="k">
            <validation-provider
                    #default="{ errors }"
                    name="inviteEmail"
                    rules="email"
                  >
            <b-form-input autocomplete="off" name="inviteEmail" v-model="formData.toBeInvitedList[k]" label="Full Name" placeholder="john@example.com" class="invite-email"/>
            </validation-provider>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-danger"
              class="btn-icon rounded-circle"
              v-if="k>1"
              @click="formData.toBeInvitedList.splice(k, 1);"
            >
              <feather-icon icon="XIcon" width="16px" height="16px"/>
            </b-button>
          </b-form-group>
          <b-link class="add-member" v-if="formData.toBeInvitedList.length != 5" @click="formData.toBeInvitedList.push('')">+ Add another member</b-link>
        </div>
        </validation-observer>
        <div class="continue-btn back-text">
          <div class="d-flex align-items-center justify-content-between">
            <b-button v-if="formData.application_mode == 'company'" v-ripple.400="'rgba(40, 199, 111, 0.15)'" @click="page_no=4" variant="primary">
              <span class="align-middle">Back</span>
            </b-button>
            <b-button v-else v-ripple.400="'rgba(40, 199, 111, 0.15)'" @click="page_no=3" variant="primary">
              <span class="align-middle">Back</span>
            </b-button>
              <b-button v-if="formData.application_mode == 'company'" variant="primary" block class="form-continue" @click="validationStep5">Continue</b-button>
              <b-button v-else variant="primary" block class="form-continue" @click="validationStep5">Finish</b-button>
          </div>
        </div>
      </b-col>
      <b-col sm="12" md="5" class="board-rightimg d-none d-md-flex align-items-center justify-content-center" style="background-color: #FFFF88">
        <b-img fluid :src="require('@/assets/images/onboarding/Screen06.svg')" class="pricing-trial-img" alt="svg img"/>
      </b-col>
    </b-row>
    <!-- End Invite admin -->
  </div>
</template>
    <script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { required, email } from '@validations';
    import {
      BAvatar,
      BCard,
      BCardText,
      BImg,
      BLink,
      BRow,
      BCol,
      BAvatarGroup,
      VBTooltip,
      BFormTextarea,
      BButton,
      BFormGroup,
      BEmbed,
    } from "bootstrap-vue";
    import vSelect from 'vue-select';
    import Ripple from "vue-ripple-directive";
    import { kFormatter } from "@core/utils/filter";
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import VuexyLogo from '@core/layouts/components/Logo.vue';
    import QuillInput from "@/components/QuillInput.vue";
    import { $themeConfig } from '@themeConfig';
    import $ from "jquery";
    import Vue from "vue";
    import { bus } from "@/main.js";
    import axios from "@axios";
    import VueCookies from "vue-cookies";
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import {
      ref,
      watchEffect,
      onMounted,
      onUnmounted,
      watch,
    } from "@vue/composition-api";
    Vue.use(VueCookies);
    import apiKeyStoreModule from '../api-keys/apiKeyStoreModule';
    
    export default {
      components: {
        BAvatar,
        BCard,
        VuexyLogo,
        BCardText,
        BButton,
        BFormTextarea,
        BImg,
        BFormGroup,
        BRow,
        BLink,
        BCol,
        BAvatarGroup,
        BEmbed,
        QuillInput,
        // validations
        ValidationProvider,
        ValidationObserver,
      },
      directives: {
        "b-tooltip": VBTooltip,
        Ripple,
      },
      mixins: [togglePasswordVisibility],
      data() {
        return {
          modulesList:[
            'Attendance',
            'Community',
            'Recruitment',
            'OKR',
          ],
          formData:{
            username:'',
            email:'',
            company_website:'',
            company_name:'',
            company_domain:'',
            selectedModules:[],
            application_mode:'company',
            password:'',
            plan:'basicPlan',
            jobTitle:'',
            termsAgreed:false,
            toBeInvitedList:[null,null],
          },
          selected: '',
          email:'',
          name:'',
          password:'',
          isTermsAgreed:false,
          jobTitleList:['CEO - Chief Executive Officer','CTO - Chief Technology Officer','CIO - Chief Innovation Officer','CDO - Chief Digital Officer','VP Of Product Management','Head Of Product','Product Manager','VP Of Marketing','VP Of Engineering','Director Of Engineering','Chief Architect','Software Architect','Engineering Project Manager','Engineering Manager','Technical Lead','Engineering Lead','Team Lead','Principal Software Engineer','Senior Software Engineer','Senior Software Developer','Software Engineer','Software Developer','Junior Software Developer','Intern Software Developer','Technical Project Manager','Project Manager','Business Account Manager','Senior Manager It','It Infra Manager','Procurement Manager','Learning And Development Manager','Learning And Development Co-Ordinators','HR Coordinator','Payroll Coordinator','Recruiting Coordinator','HR Specialist','HR Generalist','Recruiter','Human Resource Information Specialist','HR Manager','Recruiting Manager','HR Business Partner','HR Director','Recruiting Director','VP Of HR','Chief Human Resource Officer','Career Consultant','Career Advisor','Assignment Coordinator','Placement Coordinator','Career Development Strategist','Personnel Agent','Human Resources Officer','Seo Manager','Seo Engineer','Digital Marketing Manager','Digital Marketing Analyst','Social Media Marketing Manager','Social Media Marketing Analyst','Marketing Technologist','Seo Consultant','Web Analytics Developer','Social Media Manager','Growth Hacker','Content Manager','Content Strategist','Others'],
          jobTitle:'',
          company_url:'',
          company_name:'',
          domain:'',
          selectedModules:['Attendance','OKR','Community','Recruitment'],
          importFile:null,
          passwordFieldType:'password',
          toBeInvitedList:[
            '',
            ''
          ],
          step1Disabled :true,
          step2Disabled :true,
          step3Disabled :true,
          step4Disabled :true,
          step5Disabled :true,
          step6Disabled :true,
          step7Disabled :true,

          // formData:{
          //   username:'Gunaseelan',
          //   email:'gunaseelane1@dckap.com',
          //   company_website:null,
          //   company_name:'Magento Meet Up',
          //   company_domain:'magento',
          //   application_mode:'community',
          //   selectedModules:[],
          //   password:'Admin@123',
          //   plan:'basicPlan',
          //   jobTitle:null,
          //   termsAgreed:false,
          //   toBeInvitedList:[
          //     'chandra@gmail.com',
          //     null
          //   ],
          // },
          email_verified:false,
          has_minimum_lenth: false,
          has_number: false,
          has_lowercase: false,
          has_uppercase: false,
          has_special: false,
           // validation
          required,
          email,
          page_no:1,
          skin :'light'
        }
      },
      watch:{
      password(){
        this.has_minimum_lenth = (this.password.length > 8);
        this.has_number    = /\d/.test(this.password);
        this.has_lowercase = /[a-z]/.test(this.password);
        this.has_uppercase = /[A-Z]/.test(this.password);
        this.has_special   = /[!@#\$%\^\&*\)\(+=._-]/.test(this.password);
      },
    },
      setup() {
        // App Name
        const { appName, appDarkLogo, appLightLogo } = $themeConfig.app
        const selectedFile = ref(null);
        const fileChanged = (e) => {
        const files = e.target.files || e.dataTransfer.files;
        if (files.length) {
          selectedFile.value = files[0];
        }
      };
        return {
          appName,
          appDarkLogo,
          appLightLogo,
          selectedFile,
          fileChanged
        }
        
      },
      created(){
        if(this.$route.params.email){
          this.formData.email = this.$route.params.email; 
          this.email_verified = true;
          this.page_no = 2 ;
        }
      },
      computed:{
        passwordToggleIcon() {
          return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
        },
      },
      mounted () {
        $(function () {
          $(document).ready(function(){
            $('.get-start').click(function(){
                $('#get-start').hide();
                $('#set-account').show();
            });
            $('.set-account').click(function(){
                $('#set-account').hide();
                $('#tellus').show();
            });
            $('.tellus').click(function(){
                $('#tellus').hide();
                $('#choose-module').show();
            });
            $('.choose-module-btn').click(function(){
                $('#choose-module').hide();
                $('#upload-data').show();
            });
            $('.upload-data-btn').click(function(){
                $('#upload-data').hide();
                $('#invite-team').show();
            });
            $('input[type="file"]').change(function(e){
              var fileName = e.target.files[0].name;
              console.log(e.target.files);
              $('.custom-file-names').append('<li> Selected file : <b>'+fileName +'</b></li>');
            });
          });
        });
  },
  methods:{
    nameChange(){
      this.formData.company_domain = this.formData.company_name.toLowerCase().replace(/[^a-z0-9]/g, "");
    },
    domainValidation(){
      this.formData.company_domain = this.formData.company_domain.toLowerCase().replace(/[^a-z0-9]/g, "");
    },
    checkEmail(){
      if(this.formData.email.includes("@gmail.com")){
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: 'Invalid Email',
        //     icon: 'EditIcon',
        //     variant: 'danger',
        //     text:'Account must be registered under organisation email - example admin@amazon.com'
        //   },
        // })
        this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Invalid Email',
          icon: 'EditIcon',
          variant: 'danger',
          text: 'Account must be registered under organisation email - example admin@amazon.com',
        },
      }, {
        timeout: 2000, 
        position: 'bottom-center',
        toastClassName:"radius-8 error-info ",
        hideProgressBar : true,
      });
      }
      else{
        axios
          .get("/register/check-email/"+this.formData.email)
          .then((response) => {
            if(response.data.success){
            //this.$router.push("/registration-completed");
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: 'Your Organization Already Has A Zircly Domain',
              //     icon: 'EditIcon',
              //     variant: 'success',
              //     text:response.data.message
              //   },
              // })

                    this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Your Organization Already Has A Zircly Domain',
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
            }
          });
      }
      
    },
    openPrivacy(){
      window.open("https://zircly.com/privacy-policy/", "_blank");
    },
    openTerms(){
      window.open("https://zircly.com/terms-of-use/", "_blank");
    },
    validationForm() {
      if(!this.formData.company_domain.includes(process.env.VUE_APP_DOMAIN)){
      this.formData.company_domain = this.formData.company_domain+'.'+process.env.VUE_APP_DOMAIN;
      }
      axios
      .post("/register/tenant",this.formData)
      .then((response) => {
        if(response.data.success){
        this.$router.push("/registration-completed");
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: 'Registration Completed',
          //     icon: 'EditIcon',
          //     variant: 'success',
          //   },
          // })
              this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Registration Completed',
              icon:'EditIcon',
              variant : 'success',
              hideClose: true,
            },
          },
          {
            timeout : 2000,
            position : 'bottom-center',
            toastClassName:"successful-info",
            hideProgressBar : true,
          })
        }
        else{

          this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Unable to Register',
          icon:'EditIcon',
          variant : 'danger',
          hideClose: true,
          text: response.data.message
          
        },
      },
      {
        timeout : 2000,
        position : 'bottom-center',
        toastClassName:"radius-8 error-info",
        hideProgressBar : true,
      })
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: 'Unable to Register',
          //     icon: 'EditIcon',
          //     variant: 'danger',
          //     text:response.data.message
          //   },
          // })
          this.formData.company_domain.replace(process.env.VUE_APP_DOMAIN, "");
        }

      })
      .catch((error) => {
        for(var err in error.response.data.errors) {
          // this.$toast({
          //     component: ToastificationContent,
          //     props: {
          //       title: 'Error in '+err,
          //       icon: 'EditIcon',
          //       variant: 'danger',
          //       text: error.response.data.errors[err][0]
          //     },
          //   })

          this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error in '+err,
          icon:'EditIcon',
          variant : 'danger',
          hideClose: true,
          text: error.response.data.errors[err][0]
          
        },
      },
      {
        timeout : 2000,
        position : 'bottom-center',
        toastClassName:"radius-8 error-info",
        hideProgressBar : true,
      })
        }
      });
    },
    SocialAuthProvider(provider) {
      const self = this;
      let data = {};
      data.url = "register/" + provider + "/redirect";
      // data.params = {
      //   is_mobile : true,
      // };
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          console.log(res);
          window.location.href = res.data.url;
        })
        .catch((err) => {
          console.log({ err: err });
        });
      return;
    },
    validationStep1() {
      this.$refs.step1.validate().then(success => {
        if (success) {
          this.page_no = 2;
        }
      })
    },
    validationStep2() {
      if(this.formData.termsAgreed == false || this.formData.termsAgreed == 0){
        // this.$toast({
        //         component: ToastificationContent,
        //         props: {
        //           title: 'Must Agree to Privacy Policy & Terms.',
        //           icon: 'AlertTriangleIcon',
        //           variant: 'danger',
        //         },
        //       })
        this.$toast({
        component: ToastificationContent,
        props: {
          title: `Must Agree to Privacy Policy & Terms.`,
          icon:'AlertTriangleIcon',
          variant : 'danger',
          hideClose: true,
          
        },
      },
      {
        timeout : 2000,
        position : 'bottom-center',
        toastClassName:"error-info",
        hideProgressBar : true,
      })
      }else{
        this.$refs.step2.validate().then(success => {
          if (success) {
            this.page_no = 3;
          }
        })
      }
      
    },
    validationStep3() {
      if(!this.formData.jobTitle && this.formData.application_mode == 'company' ){
        // this.$toast({
        //         component: ToastificationContent,
        //         props: {
        //           title: 'Must select your job title',
        //           icon: 'AlertTriangleIcon',
        //           variant: 'danger',
        //         },
        //       })


              this.$toast({
        component: ToastificationContent,
        props: {
          title: `Must select your job title`,
          icon:'AlertTriangleIcon',
          variant : 'danger',
          hideClose: true,
          
        },
      },
      {
        timeout : 2000,
        position : 'bottom-center',
        toastClassName:"error-info",
        hideProgressBar : true,
      })
      }
      else if(!this.formData.company_domain){
        // this.$toast({
        //         component: ToastificationContent,
        //         props: {
        //           title: 'Company Domain is required',
        //           icon: 'AlertTriangleIcon',
        //           variant: 'danger',
        //         },
        //       })
              this.$toast({
        component: ToastificationContent,
        props: {
          title: `Company Domain is required`,
          icon:'AlertTriangleIcon',
          variant : 'danger',
          hideClose: true,
          
        },
      },
      {
        timeout : 2000,
        position : 'bottom-center',
        toastClassName:"error-info",
        hideProgressBar : true,
      })
      }else{
        this.$refs.step3.validate().then(success => {
          if (success ) {
            
            if(this.formData.application_mode == 'company'){
              this.page_no  = 4;
            }else{
              this.page_no = 6;
            }
          }
        })
      }
    },
    validationStep4() {
      if(this.formData.selectedModules.length == 0){
        // this.$toast({
        //         component: ToastificationContent,
        //         props: {
        //           title: 'Must select at least one module',
        //           icon: 'AlertTriangleIcon',
        //           variant: 'danger',
        //         },
        //       })

              this.$toast({
        component: ToastificationContent,
        props: {
          title: `Must select at least one module`,
          icon:'AlertTriangleIcon',
          variant : 'danger',
          hideClose: true,
          
        },
      },
      {
        timeout : 2000,
        position : 'bottom-center',
        toastClassName:"error-info",
        hideProgressBar : true,
      })
      }else{
        this.page_no = 6;
      }
    },
    validationStep5() {
      var invalidEmails = this.validateEmails(this.formData.toBeInvitedList);
      console.log(invalidEmails);
      var invalid_count = 0;
      invalidEmails.forEach((email, index) => {
        if(email['email']){

          this.$toast({
        component: ToastificationContent,
        props: {
          title: email['email'] +' email is invalid',
          icon:'AlertTriangleIcon',
          variant : 'danger',
          hideClose: true,
          
        },
      },
      {
        timeout : 2000,
        position : 'bottom-center',
        toastClassName:"error-info",
        hideProgressBar : true,
      })
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: email['email'] +' email is invalid',
          //     icon: 'AlertTriangleIcon',
          //     variant: 'danger',
          //   },
          // });
          invalid_count = invalid_count +1
        }        
      });
      if(invalid_count== 0){
        this.validationForm();
      }
    },
    validateEmails(emails) {
      const invalidEmails = [];
      
      emails.forEach((email, index) => {
        const isValid = /^[\w.-]+@[a-zA-Z_-]+?\.[a-zA-Z]{2,3}$/.test(email);
        if (!isValid) {
          invalidEmails.push({ email, position: index });
        }
      });
      
      return invalidEmails;
    }
  }

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.has_required{
  text-decoration: line-through;
  color:#689868;
}
</style>